import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "runit が便利なので、使い方を紹介した話〜メドレー TechLunch〜",
  "date": "2017-10-04T03:00:00.000Z",
  "slug": "entry/2017/10/04/120000",
  "tags": ["medley"],
  "hero": "./2017_10_04.png",
  "heroAlt": "runit"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`メドレー開発本部の nakatani です。`}</p>
    <p>{`開発本部で定期的に開催している勉強会「TechLunch」で、`}<a parentName="p" {...{
        "href": "https://smarden.org/runit/"
      }}>{`runit`}</a>{`という unix のプロセススーパバイザについてお話しました。
その内容について紹介させていただきます。`}</p>
    <p>{`runit 自体は特に目新しい技術ではなく(Linux の busybox に収められていたりする枯れた技術です)、大して難しい話題でもありません。`}</p>
    <p>{`ただ、個人的には便利に使っている`}<strong parentName="p">{`手放せないツール`}</strong>{`であり、もしスーパバイザというものの存在を知らずに`}<strong parentName="p">{`使わずにいる人がいると勿体無いなあ`}</strong>{`という思いから、TechLunch のテーマとして取り上げた次第です。`}</p>
    <h1>{`runit とはなんなのか`}</h1>
    <p>{`プロセスをデーモンとして立ち上げて、プロセスが死んでも再度起動し続けてくれるツール郡です。C 言語で開発されています。`}</p>
    <p>{`Linux などの unix ではたいてい標準で init, Upstart, Systemd, launchd などのスーパバイザが組み込まれています。
runit はそれらと同じような位置づけのものです。`}</p>
    <p>{`qmail の作者である`}<a parentName="p" {...{
        "href": "https://cr.yp.to/djb.html"
      }}>{`djb`}</a>{`が作った`}<a parentName="p" {...{
        "href": "https://cr.yp.to/daemontools.html"
      }}>{`daemontools`}</a>{`の後継のプロダクトです。`}</p>
    <h1>{`runit があると何が便利なのか`}</h1>
    <h2>{`■ マシンが起動しているかぎり、プロセスを動作させ続けることができる`}</h2>
    <p>{`マシンを立ち上げたあとに、起動コマンドを叩いたり、プロセスが落ちたときに再起動をする必要がありません。
また、フォアグラウンドで動作するプロセスを起動した後に、端末を切り離す操作をする必要もありません。`}</p>
    <p>{`ただ、これは他のスーパバイザでも同じことが実現できます。`}</p>
    <h2>{`■ その場しのぎで作ったスクリプトを、ほぼそのままデーモン化できる`}</h2>
    <p>{`Shell, Ruby, Perl, Haskell どのような言語で作ったスクリプトであっても、
`}<strong parentName="p">{`シェルなどで実行可能なファイルがあれば、それをそのままデーモンとして実行することができます`}</strong>{`。`}</p>
    <h2>{`■ スクリプトの標準出力をそのままログファイルとして扱うことができる`}</h2>
    <p>{`svlogd というプログラムが、デーモンの標準出力をログ化し、ローテーションなどの面倒も見てくれます。
自作のデーモンが思った通りに動かない際のデバッグが容易です。`}</p>
    <h1>{`macOS への導入方法`}</h1>
    <p>{`macOS に導入するための手順を記載します。詳しくはスライドや runit のドキュメントなどを理解して使うようにしてください。`}</p>
    <p>{`Xcode や Homebrew を macOS に導入していることが前提です。`}</p>
    <h2><code parentName="h2" {...{
        "className": "language-text"
      }}>{`/service`}</code>{`を root の runit ディレクトリとして設定する手順`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ brew `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` runit `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# macports feels better.`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /service
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`EOF`}<span parentName="span" {...{
              "className": "token bash punctuation"
            }}>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`sudo`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`tee`}</span>{` /Library/LaunchDaemons/runit.plist`}</span>{`
<?xml version='1.0' encoding='UTF-8'?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN"
"https://www.apple.com/DTDs/PropertyList-1.0.dtd" >
<plist version='1.0'>
  <dict>
    <key>Label</key><string>runit</string>
    <key>ProgramArguments</key>
    <array>
      <string>sh</string><string>-c</string>
      <string>PATH="/usr/local/sbin:/usr/local/bin:`}<span parentName="span" {...{
              "className": "token environment constant"
            }}>{`$PATH`}</span>{`"
    exec '/usr/local/bin/runsvdir' '/service'
      </string>
      <string>;</string>
      <string>--pid=exec</string>
    </array>
    <key>Debug</key><false/><key>Disabled</key><true/><key>KeepAlive</key><true/>
  </dict>
</plist>
EOF`}</span>{`
$ launchctl load -w `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`/Library/LaunchDaemons/runit.plist
$ launchctl list `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` runit`}</code></pre></div>
    <h2>{`自作スクリプトをデーモンにする手順`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /service/
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p hello/log `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# log を同時につくると runsvdir が log の準備もする`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` hello
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`EOF`}<span parentName="span" {...{
              "className": "token bash punctuation"
            }}>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`sudo`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`tee`}</span>{` run`}</span>{`
#!/usr/bin/env ruby
# 自作スクリプト
while true
  puts("hello ruby #{Time.now.to_i % 100}");
  STDOUT.flush();
  sleep(1);
end
EOF`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<<`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`EOF`}<span parentName="span" {...{
              "className": "token bash punctuation"
            }}>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`sudo`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`tee`}</span>{` log/run`}</span>{`
#!/bin/sh
exec svlogd -ttt .
EOF`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`755`}</span>{` run log/run
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tail`}</span>{` -F log/current `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ログが見られる。`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` sv st `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# daemon の状態を確認する。`}</span>{`
run: .: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pid `}<span parentName="code" {...{
            "className": "token number"
          }}>{`35517`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 46s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` run: log: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pid `}<span parentName="code" {...{
            "className": "token number"
          }}>{`34727`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 456s
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` sv st /service/hello/ `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ディレクトリの指定方法は自由。`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` sv t `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# TERM シグナルを daemon に送る`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` sv st `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
run: .: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pid `}<span parentName="code" {...{
            "className": "token number"
          }}>{`35589`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 1s`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` run: log: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pid `}<span parentName="code" {...{
            "className": "token number"
          }}>{`34727`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 470s `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 起動時間が 1s になってる。`}</span></code></pre></div>
    <h1>{`まとめ`}</h1>
    <p>{`結局のところ、`}<strong parentName="p">{`「使えばわかるし使わないと便利さがよくわからない」`}</strong>{`というのが正直なところです。
そのため、TechLunch においては、`}<strong parentName="p">{`使うための手順`}</strong>{`を時間をかけて解説をするようにしました。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/79a0358fe5e6414dbe34717bce3ee066" title="runit が便利なので、使い方を紹介した話 /runit" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "430px"
    }} data-ratio="1.302325581395349"></iframe>
    <p>{`みなさんも興味があれば、ぜひ導入して使ってみてください。`}</p>
    <p>{`僕自身、開発マシンである macbook pro に runit を入れて、開発環境の Ruby や MongoDB, Elasticsearch, Nginx などのサーバ群、
定期的に動かしたいちょっとしたスクリプトなどを runit で管理しています。`}</p>
    <p>{`異なる設定のサーバ群を一つのマシンに同居させる場合も、設定ファイルを分けて別ポートで立ち上げたりしています。`}</p>
    <p>{`以前のプロジェクトでは本番環境を runit で構築したこともありますし、今のプロジェクトでも、たまったゴミデータを削除し続けるスクリプトを runit で対応してそのまま放置(放置しても OK なくらいメンテナンスフリー)していたりします。`}</p>
    <p>{`最近はクラウドやコンテナ技術が活況であり、環境を抽象化しようという流れがあります。しかしながら、そもそもプロセスや UNIX OS 自体が環境を抽象化するための技術群です。そういった基本的な技術と仲良くすることで、物事がシンプルになることがあるのではないかと考えたりしながら、日々開発に取り組んでいます。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      